import React, { useState } from 'react';
import tw from 'twin.macro'; // eslint-disable-line import/no-extraneous-dependencies
import Row from 'components/Row';
import styled, { css } from 'styled-components';

const Wrapper = styled(Row)(() => [
  tw` h-10 w-20 rounded-lg relative bg-transparent mt-1`,
  css`
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  `
]);
const Input = styled.input(() => [
  tw`w-full bg-gray-300 flex items-center`,
  tw`outline-none focus:outline-none`,
  tw`text-center text-gray-700 font-semibold text-sm hover:text-purple-900 focus:text-purple-900  md:text-base cursor-default `
]);
const Button = styled.button(({ right }) => [
  tw`bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20  cursor-pointer outline-none`,
  right ? tw`rounded-r` : tw`rounded-l`
]);
const ButtonText = tw.span`m-auto text-2xl font-thin`;

const NumberInput = ({ name, setValue }) => {
  const [count, setCount] = useState(0);
  const updateCount = (val) => {
    if (val < 0) {
      setCount(count);
      setValue(count);
      return;
    }
    setCount(val);
    setValue(val);
  };
  return (
    <Wrapper>
      <Button
        onClick={(e) => {
          e.preventDefault();
          if (count === 0) return;
          updateCount(count - 1);
        }}
      >
        <ButtonText>−</ButtonText>
      </Button>
      <Input
        type="number"
        name={name}
        value={count}
        // onChange={(v) => updateCount(v.target.value)}
      />
      <Button
        right
        onClick={(e) => {
          e.preventDefault();
          updateCount(count + 1);
        }}
      >
        <ButtonText>+</ButtonText>
      </Button>
    </Wrapper>
  );
};
NumberInput.defaultProps = {
  name: 'Count',
  setValue: () => {}
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line react/jsx-props-no-spreading
export default NumberInput;
