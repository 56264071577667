import React from 'react';
import tw, { styled } from 'twin.macro';
import Col from 'components/Col';
import Text from 'components/Text';
import Number from 'components/Number';
import Row from 'components/Row';
import NumberInput from 'components/NumberInput';
import { decode } from 'js-base64';
import { DateInput as GDateInput } from 'grommet/components/DateInput';
import { Box } from 'grommet/components/Box';
import { Checkbox, CheckboxSelected } from 'grommet-icons';
import {
  List,
  Tip,
  Tag,
  Image,
  Text as GText,
  Select as GSelect,
  DateInput,
  RadioButtonGroup
} from 'grommet';
import moment from 'moment';

const TicketWrapper = styled.div(() => [tw`w-full p-4`]);

const TicketName = styled(Text)(() => [tw`items-center align-middle`]);

const Status = styled(Text)(() => [
  tw`text-sm rounded-lg bg-yellow-200 px-4 py-1 word-break["keep-all"]`
]);

interface TicketProps {
  event: namespace.Event;
  items?: namespace.TicketEntry[];
  dates: any;
  selectedDay: string;
  selectedDays: any;
  onSelectedDays: any;
  selectedLocation: any;
  onDayChange: any;
}

interface TicketItemProps {
  name: string;
  quantity?: number;
  price?: number;
  skipCalc?: boolean;
}

function TicketList({ items, event, onItemChange, ...props }) {
  return (
    <Box margin={{ top: 'medium' }}>
      <List
        data={items}
        primaryKey={({ name, description, sold, available }) => (
          <Box gap="small">
            <GText weight="bold">{name}</GText>
            <GText size="small">
              {available === 0
                ? 'Unlimited Tickets'
                : `${available - (sold?.count || 0)} ticket(s) left`}
            </GText>
            <Text
              style={{ fontSize: 14, whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{
                __html: description?.includes('b64:')
                  ? decode(description.split('b64:')[1])
                  : description
              }}
              small
            />
          </Box>
        )}
        secondaryKey={({ currency, status, price }) => (
          <Box pad={{ vertical: 'small' }}>
            <Number currency={currency} amount={price} {...props} />
            {status === 'hidden' && (
              <a href={`/host/event/dashboard/${event.id}/ticket-types/`}>
                <Status size="xsmall" label="This ticket is hidden" />
              </a>
            )}
          </Box>
        )}
        action={(item, index) => {
          if (event.event_type !== 'multiple_days')
            return item.available === 0 || (item.sold?.count || 0) < item.available ? (
              <NumberInput setValue={(v) => onItemChange(item, v)} />
            ) : (
              <Box
                align="center"
                justify="center"
                alignSelf="center"
                background={{ color: 'purple-600' }}
                pad="small"
                round={{ size: 'small' }}
              >
                <GText color="white" weight="bolder">
                  Sold Out
                </GText>
              </Box>
            );
          return null;
        }}
      />
    </Box>
  );
}
function TicketItem({
  name,
  event,
  description,
  quantity,
  price,
  currency,
  skipCalc,
  setValue,
  ...props
}) {
  const text = description?.includes('b64:') ? decode(description.split('b64:')[1]) : description;
  return (
    <Box width="100%" direction="row" gap="small" justify="between" align="start">
      <Box>
        <TicketName label={name} {...props} bold />
        <Text dangerouslySetInnerHTML={{ __html: text }} small />
      </Box>
      <Box direction="row" gap="small">
        <Row vCentered spacing="sm">
          <Number currency={currency} amount={price} {...props} />
        </Row>
        {event.event_type !== 'multiple_days' && (
          <Row spacing="sm">
            <NumberInput setValue={setValue} />
          </Row>
        )}
      </Box>
    </Box>
  );
}
TicketItem.defaultProps = {
  name: '',
  price: 0
};
function TicketSelector({
  event,
  children,
  items,
  tax,
  onItemChange,
  dates,
  selectedDay,
  selectedDays,
  onSelectedDays,
  selectedLocation,
  onLocationChange,
  onDayChange,
  ...props
}: TicketProps) {
  const [selectedTicket, setSelectedTicket] = React.useState(null);

  let lastGroup = '';
  const itemComponents = [];
  items.forEach((item) => {
    itemComponents.push(
      <>
        {lastGroup !== item.group_id && item.group_name && (
          <Col spacing="sm">
            <Text label={item.group_name} medium bolder />
            <Text label={item.group_description} bold />
          </Col>
        )}
        <TicketItem
          key={item.name}
          event={event}
          currency="KES"
          setValue={(v) => onItemChange(item, v)}
          {...item}
        />
      </>
    );
    lastGroup = item.group_id;
  });

  React.useEffect(() => {
    if (!event || !selectedTicket || !selectedDays) return;
    const item = items.filter((t) => `${t.id}` === selectedTicket)[0];
    onItemChange(
      item,
      moment(selectedDays[selectedDays.length - 1]).diff(moment(selectedDays[0]), 'days')
    );
  }, [event, selectedTicket, selectedDays]);
  const startDate = moment(event?.start_date).format('YYYY-MM-DD');
  const endDate = moment(event?.end_date).format('YYYY-MM-DD');

  let dateSection;
  if (!(startDate > endDate) && !(startDate < endDate)) {
    dateSection = (
      <Box>{/* <GText size="medium">{moment(startDate).format('MMMM D, YYYY')}</GText> */}</Box>
    );
  } else {
    dateSection = dates.length > 0 && (
      <Box gap="medium">
        {event.event_type === 'multiple_days' ? (
          <Box gap="small">
            <Text label="Please choose the range of days" small />
            <DateInput
              value={selectedDays}
              buttonProps={{
                primary: true,
                label:
                  selectedDays &&
                  `${moment.utc(new Date(selectedDays[0])).format('DD MMM')} ${
                    selectedDays.length > 1
                      ? ` - ${moment.utc(new Date(selectedDays[1])).format('DD MMM')}`
                      : ''
                  }`
              }}
              calendarProps={{
                fill: true,

                bounds: [dates[0], dates[dates.length - 1]].map((d) =>
                  d.toLocaleDateString('en-us', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                  })
                )
              }}
              onChange={onSelectedDays}
            />
          </Box>
        ) : (
          <Box gap="small">
            <Text label="Please choose the day of the event" small />
            {/* <Select
                small
                name="day"
                placeholder="Select a different day"
                onChange={onDayChange}
                value={selectedDay}
              >
                {dates.map((val) => (
                  <option key={val.toString()} value={val}>
                    {val.toLocaleDateString('en-us', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric'
                    })}
                  </option>
                ))}
              </Select> */}
            <GDateInput
              format="mm/dd/yyyy"
              value={selectedDay}
              onChange={onDayChange}
              calendarProps={{
                fill: true,
                daysOfWeek: true,
                bounds: [startDate.toString(), endDate.toString()]
              }}
            />
          </Box>
        )}
      </Box>
    );
  }
  return (
    <TicketWrapper {...props}>
      <Box gap="small" pad={{ horizontal: 'medium' }}>
        <Box gap="small">
          <Box pad={{ bottom: 'small' }}>
            <Text label="Booking" large bold />
          </Box>
          {dateSection}
          {event?.venues?.length > 0 && (
            <Box gap="small">
              <Text label="Please choose a location" small />
              <GSelect
                size="medium"
                closeOnChange
                value={
                  <Box direction="row">
                    {selectedLocation ? (
                      <Box pad={{ vertical: 'xsmall', horizontal: 'small' }} margin="xsmall">
                        <GText size="medium">{selectedLocation.venue.name}</GText>
                      </Box>
                    ) : (
                      <Box pad={{ vertical: 'xsmall', horizontal: 'small' }} margin="xsmall">
                        <GText size="small">Choose a location</GText>
                      </Box>
                    )}
                  </Box>
                }
                options={event.venues}
                // selected={selectedLocation}
                onChange={({ option }) => {
                  onLocationChange(option);
                }}
              >
                {(datum) => (
                  <Box direction="row" gap="small" pad="small">
                    <Box width="xsmall" height="xsmall">
                      <Image src={datum.venue.image_url || ''} fit="cover" />
                    </Box>
                    <Box pad={{ right: 'small' }}>
                      <GText weight="bold">{datum.venue.name}</GText>
                      <GText size="small">{datum.venue.address_line1}</GText>
                    </Box>
                  </Box>
                )}
              </GSelect>
            </Box>
          )}
        </Box>
        <Box pad={{ top: 'small' }} width="100%">
          <Text label="Please choose your ticket(s)" small />
          {event?.event_type === 'multiple_days' ? (
            <Box gap="small">
              <RadioButtonGroup
                gap="small"
                options={items.map((t) => ({ label: t.name, value: `${t.id}`, id: t.id }))}
                value={selectedTicket}
                onChange={(e) => {
                  const option = e.target.value;
                  setSelectedTicket(option);
                  for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    onItemChange(item, 0);
                  }
                }}
              >
                {(option, { checked, focus, hover }) => {
                  let background;
                  if (checked) {
                    background = 'brand';
                  } else if (hover) {
                    background = 'light-4';
                  } else if (focus) {
                    background = 'light-4';
                  } else {
                    background = 'light-2';
                  }
                  const item = items.filter((t) => `${t.id}` === option.value)[0];
                  return (
                    <Box
                      gap="small"
                      direction="row"
                      background={background}
                      round="small"
                      pad="xsmall"
                    >
                      {checked && option.value === selectedTicket ? (
                        <CheckboxSelected />
                      ) : (
                        <Checkbox />
                      )}
                      <TicketItem key={item.name} event={event} currency="KES" {...item} />
                    </Box>
                  );
                }}
              </RadioButtonGroup>
            </Box>
          ) : (
            <Box>
              <TicketList items={items} event={event} onItemChange={onItemChange} />
              {/* {itemComponents} */}
            </Box>
          )}
        </Box>
        {/* {items?.map((item) => {
          return (
            <TicketItem
              key={item.name}
              currency="KES"
              setValue={(v) => onItemChange(item, v)}
              {...item}
            />
          );
        })} */}
      </Box>
    </TicketWrapper>
  );
}

TicketSelector.defaultProps = {
  items: [],
  tax: 14.0,
  onLocationChange: () => {},
  onItemChange: (item: namespace.TicketEntry) => {}
};
export default TicketSelector;
